import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import Image from '../components/image'
import Link from '../components/link'
import PageLinks from '../components/page-links'
import Promotions from '../components/promotions'
import Sponsors from '../components/sponsors'
import styles from './for-advertisers.module.css'
import SEO from '../components/seo'

export const ForAdvertisersTemplate = ({ page }) => {
  const {
    title,
    coverImage,
    externalLink,
    otherSections,
    sponsors,
    pageLinks,
  } = page.frontmatter

  return (
    <>
    <SEO 
      title={"Advertise with the Projector Cinema | Cinema Advertising"}
      description={"Looking for creative means to pitch your cool stuff to a captive creative audience? Our cinema screens are perfect for that. Contact us for advertising info."}
    />
    <div className="container justify-between py-5 lg:py-20">
      <h1 className={`${styles.title} text-center`}>{title}</h1>
      <div className="mt-12">
        <Image image={coverImage} />
      </div>
      <div className="mt-12 text-center mx-auto w-full md:w-4/5 lg:w-3/5">
        <div
          className={styles.body}
          dangerouslySetInnerHTML={{ __html: page.html }}
        />
      </div>
      {externalLink && externalLink.linkURL ? (
        <div className="mt-12 text-center">
          <Link className="no-underline inline-block" to={externalLink.linkURL}>
            <div className="btn-primary">
              {externalLink.callToAction || 'Find Out More'}
            </div>
          </Link>
        </div>
      ) : (
        ''
      )}
      <Promotions
        promotions={otherSections.map(o => ({ ...o, coverImage: o.image }))}
        alternateStyling={true}
        className={`${styles.promotions}`}
      />
      {sponsors && sponsors.length ? (
        <div className="mt-40">
          <Sponsors sponsors={sponsors} />
        </div>
      ) : (
        ''
      )}
      <div className="w-full mt-32">
        <PageLinks links={pageLinks} />
      </div>
    </div>
    </>
  )
}

const ForAdvertisersPage = ({ data }) => {
  return <ForAdvertisersTemplate {...data} />
}

ForAdvertisersPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object,
  }),
}

export default ForAdvertisersPage

export const pageQuery = graphql`
  query ForAdvertisersPage {
    page: markdownRemark(fields: { slug: { eq: "/for-advertisers/" } }) {
      frontmatter {
        title
        coverImage {
          childImageSharp {
            fluid(maxWidth: 1159, maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        externalLink {
          callToAction
          linkURL
        }
        otherSections {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 576, maxHeight: 384, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          bodyHtml
          callToAction
          linkURL
        }
        sponsors {
          label
          sponsorType
          group {
            logo {
              childImageSharp {
                fluid(maxWidth: 150, maxHeight: 80, quality: 90, fit: CONTAIN) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            name
            linkURL
          }
        }
        pageLinks {
          title
          subtitle
          linkURL
        }
      }
      html
    }
  }
`
